@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display&family=Fira+Code&family=Montserrat:ital,wght@0,300;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-container {
  min-height: 90vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: block;
  position: relative;
  margin: 25px;;
  padding-bottom: 60px; /* height of your footer */
 }

 /* icons */

 i {
   color: #1e212d
 }

 /* navbar and links */

 .link {
  color:  #faf3e0;
  text-decoration: underline;  
}

.link:hover {
  color: #1e212d;
  text-decoration: underline;
}

.navbar {
  text-align: right;
}

.navbar li {
  display: inline-block;
  font-family: 'Big Shoulders Display', cursive;
  color: #b68973;
  padding: 20px;
  font-size: 35px;
}

.navbar li:hover {
  font-family: 'Big Shoulders Display', cursive;
  color: #1e212d;
  display: inline-block; 
  padding: 20px;
  font-size: 35px;
}

/* HOME PAGE  */

.homepage {
  text-align: center;
  margin: 12%;
}

.homepage-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 55px;
  color: #faf3e0;
  margin: 18px;
}

.name {
  font-family: 'Montserrat', sans-serif;
  color: #1e212d;
  font-size: 55px
}

.about-me {
  font-family: 'Fira Code', monospace;
  color: hsl(228, 20%, 15%);
  text-align: left;
  font-size: 18px;
  padding: 10px;
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.about, .connect {
  font-family: 'Fira Code', monospace;
  color: #1e212d;
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
}

/* icons for connections footer */

.connect-icons {
  text-align: center;
  font-size: 30px;
}

.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 35px;
  color: #faf3e0;
  text-align: center;
  padding-top: 15px;
}

.text-content {
  font-family: 'Fira Code', monospace;
  color: #1e212d;
  font-size: 18px;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;

}

/* about me section */

.profile-img {
  height: 15%;
  width: 15%;
  border-radius: 50%;
  border: 3px solid #faf3e0;
  margin-top: 15px;
}

.intro {
  font-family: 'Fira Code', monospace;
  color: #1e212d;
  font-size: 18px;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 20px;

}

/* PROJECT PAGE  */

.projects {
  font-family: 'Montserrat', sans-serif;
  color: #1e212d;
  text-align: center;
}

.proj-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  color: #faf3e0;
  padding-top: 15px;
}

.description {
  font-family: 'Fira Code', monospace;
  color: #1e212d;
  font-size: 18px;
  padding-left: 2%;
  padding-right: 2%;
}

.screenshots {
  height: 50%;
  width: 50%;
  margin: 10px;
  border: 1px solid #1e212d;
  border-radius: 5px;
}


 /* SKILLS SECTIONS IN ABOUT ME */

.skills {
  text-align: center;
}

.tech-icons{
  padding-top: 10px;
}

#toolbox {
  font-size: 50px;
  color: #faf3e0;
}

#footer {
  font-family: 'Montserrat', sans-serif;
  color: #1e212d;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
}